import { all, put, takeLatest } from 'redux-saga/effects'

import { fetchHelpArticlesSuccess, fetchHelpArticlesError } from './actions'
import { FETCH_HELP_ARTICLES } from './constants'

function* getHelpArticles() {
  if (!window.config) {
    yield put(fetchHelpArticlesError('Could not read from configuration'))
  }

  try {
    const manifestUrl = window.config.helpCenterManifestUrl
    if (!manifestUrl) {
      yield put(
        fetchHelpArticlesError(
          'No source for help documents has been configured'
        )
      )
      return
    }

    const json = yield fetch(manifestUrl).then(response => response.json())

    yield put(fetchHelpArticlesSuccess(json))
  } catch (e) {
    yield put(fetchHelpArticlesError('Error fetching help documents', e))
    //eslint-disable-next-line no-console
    console.error('Failed to fetch manifest of help articles', e)
  }
}

function* helpCenterSaga() {
  yield all([takeLatest(FETCH_HELP_ARTICLES, getHelpArticles)])
}

export default helpCenterSaga
