import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import moment from 'moment'

import Hoverable from 'components/Hoverable'
import Icon from 'components/Icon'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import CloudBadge from 'components/CloudBadge'
import MarkdownDisplay from 'components/MarkdownDisplay'

const Article = ({ article, theme }) => {
  const openLink = () => {
    window.open(article.get('url'), '_blank')
  }

  const styles = {
    container: {
      padding: '0.5em 1em',
    },
    header: {
      display: 'grid',
      gridTemplateColumns: 'auto 1fr auto',
    },
    title: {
      fontWeight: 400,
      paddingRight: '0.5em',
    },
    date: {
      color: theme.neutralMedium,
      fontWeight: 400,
    },
    description: {
      color: theme.dark,
      fontSize: '0.95em',
      margin: '0.5em 0',
    },
    icon: {
      marginRight: '0.5em',
    },
  }

  const getIcon = () => {
    const icon = article.get('iconName')

    if (!icon) {
      return <span />
    }

    if (
      icon === 'aws' ||
      icon === 'azure' ||
      icon === 'sonrai' ||
      icon === 'gcp'
    ) {
      return <CloudBadge type={icon} style={styles.icon} />
    }

    return <Icon fa name={icon} style={styles.icon} />
  }

  return (
    <Hoverable
      onClick={openLink}
      style={styles.container}
      hoverStyle={{ backgroundColor: theme.highlight }}
    >
      <div style={styles.header}>
        {getIcon()}
        <span style={styles.title}>{article.get('name')}</span>
        {article.get('date') && (
          <span style={styles.date}>
            {moment(article.get('date')).format('ll')}
          </span>
        )}
      </div>

      {article.get('description') && (
        <div style={styles.description}>
          <MarkdownDisplay content={article.get('description')} />
        </div>
      )}
    </Hoverable>
  )
}

Article.propTypes = {
  article: ImmutablePropTypes.contains({
    url: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    date: PropTypes.string,
    iconName: PropTypes.string,
  }),
  theme: themeShape,
}

export default themeable(Article)
