import { fromJS } from 'immutable'

import {
  FETCH_HELP_ARTICLES,
  FETCH_HELP_ARTICLES_SUCCESS,
  FETCH_HELP_ARTICLES_ERROR,
} from './constants'
import { handleActions } from 'redux-actions'

const initialState = fromJS({
  loading: false,
  helpArticles: [],
  error: null,
})

const helpCenterReducer = handleActions(
  {
    [FETCH_HELP_ARTICLES_SUCCESS]: (state, { payload }) =>
      state
        .set('helpArticles', fromJS(payload))
        .set('loading', false)
        .set('error', null),
    [FETCH_HELP_ARTICLES]: state =>
      state.set('loading', true).set('error', null),
    [FETCH_HELP_ARTICLES_ERROR]: (state, { payload }) =>
      state.set('loading', false).set('error', payload),
  },
  initialState
)

export default helpCenterReducer
