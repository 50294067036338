import { createSelector } from 'reselect'
import { Map, List } from 'immutable'

export const selectHelpCenterDomain = state => state.get('helpCenter', Map())

export const selectHelpArticles = createSelector(
  selectHelpCenterDomain,
  state => state.get('helpArticles') || List()
)

export const selectHelpArticlesLoading = createSelector(
  selectHelpCenterDomain,
  state => state.get('loading') || false
)

export const selectError = createSelector(
  selectHelpCenterDomain,
  state => state.get('error') || false
)
