import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { bindActionCreators, compose } from 'redux'
import ImmutablePropTypes from 'react-immutable-proptypes'
import TableLoader from 'components/TableLoader'
import injectReducer from 'utils/injectReducer'
import injectSaga from 'utils/injectSaga'
import { List } from 'immutable'
import EmptySection from 'components/EmptySection'
import CenterContent from 'components/CenterContent'
import Error from 'components/Error'
import qs from 'query-string'
import { SUPPORT_EMAIL } from 'appConstants'
import Button from 'components/Button'
import BorderedCard from 'components/BorderedCard'
import SearchInput from 'components/SearchInput'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import reducer from './reducer'
import sagas from './sagas'
import {
  selectHelpArticlesLoading,
  selectHelpArticles,
  selectError,
} from './selectors'
import { fetchHelpArticles } from './actions'
import Article from './Article'

export class HelpCenter extends React.Component {
  constructor(props) {
    super(props)

    if (props.helpArticles.isEmpty()) {
      props.fetchHelpArticles()
    }

    const preloadSearch = qs.parse(props.location.search).preloadSearch

    this.state = {
      search: preloadSearch || '',
    }

    this.styles = {
      container: {
        display: 'grid',
        gridTemplateRows: 'auto 1fr',
        gridTemplateAreas: '"header" "content"',
        padding: '1em',
        height: '100%',
      },
      header: {
        gridArea: 'header',
        padding: '1em 3em',
        display: 'grid',
        gridTemplateColumns: '40% 1fr',
        gridColumnGap: '4em',
      },
      title: {
        fontSize: '1.3em',
        fontWeight: 400,
        marginBottom: '1em',
        textAlign: 'center',
      },
      content: {
        gridArea: 'content',
        overflow: 'auto',
        padding: '1em 3em',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridColumnGap: '4em',
      },
      sectionTitle: {
        fontVariant: 'small-caps',
        color: this.props.theme.neutralDark,
        fontSize: '1.3em',
        fontWeight: 400,
        borderBottom: `2px solid ${this.props.theme.neutralLight}`,
      },
      articleWrapper: {
        margin: '0.5em',
      },
      sectionContainer: {
        marginBottom: '2em',
      },
      sectionContent: {
        maxHeight: '600px',
        overflow: 'auto',
      },
      supportWrapper: {
        textAlign: 'right',
      },
    }
  }

  setSearch = e => {
    this.setState({
      search: e.target.value,
    })
  }

  getFilteredArticles = () => {
    if (!this.state.search) {
      return this.props.helpArticles
    }

    const searchVal = this.state.search.toLowerCase()

    return this.props.helpArticles
      .map(category =>
        category.update('articles', articles =>
          articles.filter(
            article =>
              article
                .get('description', '')
                .toLowerCase()
                .includes(searchVal) ||
              article
                .get('name', '')
                .toLowerCase()
                .includes(searchVal)
          )
        )
      )
      .filterNot(cat => cat.get('articles', List()).isEmpty())
  }

  renderSection = category => {
    return (
      <BorderedCard style={this.styles.sectionContainer}>
        <div style={this.styles.sectionTitle}>
          {category.get('categoryName')}
        </div>
        <div style={this.styles.sectionContent}>
          {category.get('articles', List()).map(article => (
            <div key={article.get('name')} style={this.styles.articleWrapper}>
              <Article article={article} />
            </div>
          ))}
        </div>
      </BorderedCard>
    )
  }

  renderContent = () => {
    if (this.props.isLoading) {
      return <TableLoader />
    }

    if (this.props.error) {
      return <Error description={this.props.error} />
    }

    if (this.props.helpArticles.isEmpty()) {
      return <CenterContent>No knowledge base articles</CenterContent>
    }

    const filteredArticles = this.getFilteredArticles()

    if (this.state.search && filteredArticles.isEmpty()) {
      return (
        <EmptySection>
          No resources matching &quot;{this.state.search}&quot;
        </EmptySection>
      )
    }

    return (
      <div style={this.styles.content}>
        {filteredArticles.map(this.renderSection)}
      </div>
    )
  }

  render() {
    return (
      <div style={this.styles.container}>
        <div style={this.styles.header}>
          <div>
            <SearchInput
              placeholder="Search the Knowledge Base"
              value={this.state.search}
              onChange={this.setSearch}
            />
          </div>

          <div style={this.styles.supportWrapper}>
            <Button color="primary" href={`mailto: ${SUPPORT_EMAIL}`}>
              Contact Support
            </Button>
          </div>
        </div>
        {this.renderContent()}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}
        >
          <a
            style={{
              padding: '0.75rem 0.75rem 0rem 0.75rem',
              fontWeight: '300',
            }}
            target="_blank"
            rel="noopener noreferrer"
            href={window.config.openSourceLicenseUrl}
          >
            Open Source Licenses
          </a>
        </div>
      </div>
    )
  }
}

HelpCenter.propTypes = {
  error: PropTypes.string,
  isLoading: PropTypes.bool,
  helpArticles: ImmutablePropTypes.list,
  fetchHelpArticles: PropTypes.func,
  theme: themeShape,
  location: PropTypes.object,
}

const mapStateToProps = createStructuredSelector({
  error: selectError,
  isLoading: selectHelpArticlesLoading,
  helpArticles: selectHelpArticles,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchHelpArticles,
    },
    dispatch
  )
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)

const withReducer = injectReducer({
  key: 'helpCenter',
  reducer: reducer,
})
const withSaga = injectSaga({ key: 'helpCenter', saga: sagas })

export default compose(
  withConnect,
  withReducer,
  withSaga,
  themeable
)(HelpCenter)
