import {
  FETCH_HELP_ARTICLES,
  FETCH_HELP_ARTICLES_SUCCESS,
  FETCH_HELP_ARTICLES_ERROR,
} from './constants'
import { createAction } from 'redux-actions'

export const fetchHelpArticles = createAction(FETCH_HELP_ARTICLES)
export const fetchHelpArticlesSuccess = createAction(
  FETCH_HELP_ARTICLES_SUCCESS
)

export const fetchHelpArticlesError = createAction(FETCH_HELP_ARTICLES_ERROR)
